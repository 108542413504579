@font-face {
  font-family: 'GT America';
  src: url('../fonts/GT-America-Standard-Regular.woff2') format('woff2'),
    url('../fonts/GT-America-Standard-Regular.woff') format('woff'),
    url('../fonts/GT-America-Standard-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'GT America Extended';
  src: url('../fonts/GT-America-Extended-Regular.woff2') format('woff2'),
    url('../fonts/GT-America-Extended-Regular.woff') format('woff'),
    url('../fonts/GT-America-Extended-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'GT America Mono';
  src: url('../fonts/GT-America-Mono-Regular.woff2') format('woff2'),
    url('../fonts/GT-America-Mono-Regular.woff') format('woff'),
    url('../fonts/GT-America-Mono-Regular.ttf') format('truetype');
}
