:root {
  --btn-color: var(--color-white);
  --btn-color-hover: var(--color-white);
  --btn-color-active: var(--btn-color-hover);
  --btn-color-focus: var(--btn-color-hover);
  --btn-border-base: var(--color-black);
  --btn-border-hover: var(--color-black-222);
  --btn-border-active: var(--btn-border-base);
  --btn-border-focus: var(--btn-border-base);
  --btn-border-focus-visible: var(--color-status-success);
  --btn-bg: var(--color-black);
  --btn-bg-hover: #444;
  --btn-bg-active: var(--btn-bg-hover);
  --btn-bg-focus: var(--btn-bg-hover);
  --btn-icon: var(--color-white);
  --btn-icon-hover: var(--btn-color-hover);
  --btn-icon-active: var(--btn-icon-hover);
  --btn-icon-focus: var(--btn-icon-hover);
  --btn-radius: 0px;
}

.btn {
  @include transition($btn-transition);
  @include extended-text;
  text-transform: uppercase;
  font-size: var(--text-xs);
  font-weight: var(--weight-medium);
  line-height: var(--leading-tightest);
  letter-spacing: var(--tracking-wide);
  box-shadow: none !important;
  padding: $base-spacer $base-spacer*1.5;
  border: 1px solid var(--btn-border-base);
  border-radius: var(--btn-radius);
  position: relative;
  color: var(--btn-color);
  background-color: var(--btn-bg);
  cursor: pointer;
  &:not(.btn-icon) {
    [class*=fa-] {
      @include transition($btn-transition);
      color: var(--btn-icon);
      font-size: var(--text-sm);
    }
  }
  @include hover-full {
    color: var(--btn-color-hover);
    background-color: var(--btn-bg-hover);
    border-color: var(--btn-border-hover);
    text-decoration: none;
    &:not(.btn-icon) {
      [class*=fa-] {
        color: var(--btn-icon-hover);
      }
    }
  }
  &.btn-icon {
    --btn-border-base: rgba($white,.12);
    --btn-border-hover: rgba($white,.5);
    --btn-color: rgba($white,.5);
    font-size: var(--text-base);
    padding: $base-spacer;
  }
  &.btn-default {
    --btn-bg: transparent;
    --btn-bg-hover: rgba(0,0,0,.05);
    --btn-color: var(--color-body-text);
    --btn-color-hover: var(--color-body-text);
    --btn-border-base: rgba(0,0,0,.1);
    --btn-border-hover: var(--btn-border-base);
    --btn-icon: rgba($black,.5);
    --btn-icon-hover: var(--btn-color-hover);
    --btn-radius: 64px;
    [class*=fa-] {
      @include transition($btn-transition);
      color: var(--btn-icon);
      font-size: var(--text-sm);
    }
  }
  &.btn-slick {
    --btn-bg: transparent;
    --btn-bg-hover: rgba(0,0,0,.75);
    --btn-border-base: rgba($white,.12);
    --btn-border-hover: rgba($white,.5);
    --btn-color: rgba($white,.5);
    --btn-radius: 64px;
    font-size: var(--text-base);
    padding: $base-spacer;
  }
}
