@function hextorgb($hex) {
  @return red($hex), green($hex), blue($hex);
}

:root {

  /* FONTS/TYPE
  --------------------------------------- */
  /* font-family */
    --font-sans: 'GT America','Helvetica Neue',Arial,sans-serif;
    --font-extended: 'GT America Extended','Helvetica Neue',Arial,sans-serif;
    --font-serif: var(--font-sans);
    --font-mono: 'GT America Mono',"Lucida Console", Courier, monospace;
    --font-awesome: 'Font Awesome 6 Pro';
    --font-display: var(--font-sans);

  /* line-height */
    --leading-none: 1;
    --leading-tightest: .875;
    --leading-tight: 1.2;
    --leading-normal: 1.5;

  /* letter-spacing */
    --tracking-normal: 0em;
    --tracking-med: .04em;
    --tracking-wide: .1em;
    --tracking-wider: .12em;

  /* font-weight */
    --weight-normal: 400;
    --weight-semibold: 500;
    --weight-bold: var(--weight-semibold);

  /* font-size */
    --text-2xs:   10px;
    --text-xs:    12px;
    --text-xs-sm: 13px;
    --text-sm:    14px;
    --text-md:    15px;
    --text-lg:    18px;
    --text-xl:    20px;
    --text-2xl:   24px;
    --text-base:  16px;

    --text-h1:    var(--text-2xl);
    --text-h2:    22px;
    --text-h3:    20px;
    --text-h4:    18px;
    --text-h5:    16px;
    --text-h6:    15px;
    --text-3xl: clamp(24px, calc(24px + (28 - 24) * ((100vw - 375px) / (1600 - 375))), 28px);
    --text-4xl: clamp(28px, calc(30px + (32 - 28) * ((100vw - 375px) / (1600 - 375))), 32px);
    --text-5xl: clamp(32px, calc(32px + (40 - 32) * ((100vw - 375px) / (1600 - 375))), 40px);
    --text-6xl: clamp(36px, calc(36px + (56 - 36) * ((100vw - 375px) / (1600 - 375))), 56px);
    --text-7xl: clamp(40px, calc(40px + (76 - 40) * ((100vw - 375px) / (1600 - 375))), 76px);
//     --text-base: var(--text-md);


  /* COLORS
  --------------------------------------- */
    --color-white:              #fff;
    --color-black:              #000;

    --color-white-f1:           #f1f1f1;
    --color-cream:              #FFFBF7;
    --color-black-111:          #111;
    --color-black-171:          #171717;
    --color-black-222:          #222;

    --color-blue:               #3393D8;
    --color-blue-dark:          #105586;
    --color-green:              #63A67A;
    --color-green-dark:         #2E6240;
    --color-orange:             #F38526;
    --color-orange-dark:        #f98d0e;
    --color-purple:             #C48CC9;
    --color-purple-dark:        #5D3C60;

    --color-brand-primary:      var(--color-blue);
    --color-brand-secondary:    var(--color-green);

    --color-status-error:       #FF7523;
    --color-status-info:        #73A5E9;
    --color-status-success:     #71B7A5;
    --color-status-warning:     #EEBE32;

    --color-body-text:          var(--color-black);
    --color-body-bg:            var(--color-cream);
    --color-overline:           var(--color-black-111);

    --headline-color:           var(--color-green-spring-light);
    --headline-color-alt:       var(--color-green-spring-light);
    --overline-color:           var(--color-yellow);

    --link-color:               var(--color-brand-primary);
    --link-color-hover:         var(--color-body-text);
    --link-color-active:        var(--link-color-hover);
    --link-color-focus:         var(--link-color-hover);
    --link-color-focus-visible: var(--color-status-success);

    --block-border-color:       var(--color-green);

    --slick-dot-color:          var(--color-white-off);

    --guest-bg-color:           var(--color-green);

    --alert-link-color:         var(--color-body-text);

    --color-accent:             var(--color-black-222);
    --color-accent-dark:        var(--color-black);

    --color-portfolio:          var(--color-black);


  /* MARGINS/PADDING
  --------------------------------------- */
  /* fluid-spacer */
    --space-xs: clamp(12px, calc(12px + (16 - 12) * ((100vw - 375px) / (1600 - 375))), 16px);
    --space-sm: clamp(24px, calc(20px + (32 - 24) * ((100vw - 375px) / (1600 - 375))), 32px);
    --space-md: clamp(30px, calc(30px + (48 - 30) * ((100vw - 375px) / (1600 - 375))), 48px);
    --space-lg: clamp(50px, calc(50px + (80 - 50) * ((100vw - 375px) / (1600 - 375))), 80px);
    --space-xl: clamp(80px, calc(80px + (128 - 80) * ((100vw - 375px) / (1600 - 375))), 128px);
    --space-xxl: clamp(130px, calc(130px + (160 - 130) * ((100vw - 375px) / (1600 - 375))), 160px);
    --space-base: calc(var(--space-xs)*1.5); // 24


  /* static gaps*/
    --static-size-base: 16px;
    --gap-base: var(--static-size-base);        // 16
    --gap-2xs: calc(var(--gap-base) * .25);     // 4
    --gap-xs: calc(var(--gap-base) * .5);       // 8
    --gap-sm: calc(var(--gap-base) * .75);      // 12
    --gap-md-s: calc(var(--gap-base) * 1.125);  // 18
    --gap-md: calc(var(--gap-base) * 1.25);     // 20
    --gap-lg: calc(var(--gap-base) * 1.5);      // 24
    --gap-lg-xl: calc(var(--gap-base) * 1.875); // 30
    --gap-xl: calc(var(--gap-base) * 2);        // 32
    --gap-2xl: calc(var(--gap-base) * 2.25);    // 36
    --gap-3xl: calc(var(--gap-base) * 3);       // 48
    --gap-64: calc(var(--gap-base) * 4);        // 64
    --gap-80: calc(var(--gap-base) * 5);        // 80
    --gap-96: calc(var(--gap-base) * 6);        // 96
    --gap-128: calc(var(--gap-base) * 8);       // 128
    --gap-144: calc(var(--gap-base) * 9);       // 144

    --grid-margin-thin: 2px;



  /* INPUTS
  --------------------------------------- */
    --input-color: rgba(17, 17, 17, 0.75);
    --input-bg: rgba(0, 0, 0, 0.04);
    --input-border-color: rgba(0, 0, 0, 0.08);
    --input-border-radius: 0;
    --input-placeholder: rgba(17, 17, 17, 0.50);
    --input-text-size: var(--text-sm);

}
