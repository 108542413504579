html {
  height: 100%;
  scroll-behavior: smooth;
  font-size: var(--text-base);
  body {
    line-height: var(--leading-normal);
    background: var(--color-body-bg);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.overline {
  @include overline;
}
.overline-sm {
  @include overline-sm;
}

img {
  @include img-fluid;
}


main.main {
  &>hr {
    margin: 0;
    border-color: rgba(0, 0, 0, 0.08);
  }
}
