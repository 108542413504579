footer.content-info {
  padding: var(--gap-96) 0 0;
  text-align: center;
  figure {
    margin: 0 auto;
    width: 32px;
  }
  .tagline {
    @include extended-text;
    font-size: var(--text-sm);
    margin: 0;
    padding: var(--gap-64) 0;
  }
  .copyright {
    @include mono-text;
    text-transform: uppercase;
    font-size: var(--text-2xs);
    opacity: .5;
    margin: 0;
    padding: var(--gap-3xl) 0;

  }
  .nav-social {
    padding-bottom: var(--gap-xl);
    ul {
      @include list-normalize-inline-block;
      li {
        margin: 0 calc(var(--space-base)/2);
        a {
          @include transition($link-transition);
          @include white-trans-50;
          font-size: var(--text-lg);
          @include hover-full {
            color: var(--color-white);
          }
        }
      }
    }
  }
}
