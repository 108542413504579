.form-control {
  @include mono-text;
  padding: .75em;
  border: 1px solid var(--input-border-color);
  height: auto;
  line-height: var(--leading-none);
  &:focus,&:focus-visible {
    border-color: var(--link-color-focus-visible);
  }
  &:focus {
		&::-webkit-input-placeholder {
		  color: transparent;
		}
		&:-moz-placeholder { /* Firefox 18- */
		  color: transparent;
		}
		&::-moz-placeholder {  /* Firefox 19+ */
		  color: transparent;
		}
		&:-ms-input-placeholder {
		  color: transparent;
		}
	}
  &:focus-visible {
    @include focus-outline-thin;
  }
}


// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @exte
  nd .btn-secondary;
}
.gform_wrapper {
  input[type=color],
  input[type=date],
  input[type=datetime-local],
  input[type=datetime],
  input[type=email],
  input[type=month],
  input[type=number],
  input[type=password],
  input[type=search],
  input[type=tel],
  input[type=text],
  input[type=time],
  input[type=url],
  input[type=week],
  select,textarea {
    @extend .form-control;
    font-size: var(--input-text-size) !important;
    padding: 12px !important;
  }
  .gform_footer {
    margin-top: 0 !important;
  }
  input[type=submit] {
    @extend .btn;
    font-size: var(--input-text-size) !important;
    width: 100%;
    &.disabled,&.button-disabled,&:disabled {
      pointer-events: none;
      cursor: not-allowed;;
      opacity: .4 !important;
//       text-transform: uppercase;
    }
  }


  .gfield {
    legend {
      @include sr-only;
    }
  }
  .gfield:not(.gfield--type-consent) {
    label {
      @include sr-only;
    }
  }
}
