header.banner {
  padding: var(--gap-lg) 0;
  @include media-breakpoint-up(lg) {
    padding: var(--gap-xl) 0;
  }
  .logo {
    img {
      width: var(--gap-64);
      @include media-breakpoint-up(md) {
        width: var(--gap-80);
      }
      @include media-breakpoint-up(lg) {
        width: var(--gap-96);
      }
    }
  }
  .tagline {
    @include extended-text;
    font-size: var(--text-sm);
    text-align: center;
    letter-spacing: var(--tracking-med);
  }
  .contact {
    text-align: right;
    .btn {
      span {
        margin-left: 12px;
      }
      [class*=fa-] {
        font-size: var(--text-sm);
      }
      @include media-breakpoint-down(md) {
        padding-left: var(--gap-base);
        padding-right: var(--gap-base);
        width: 54px;
        height: 54px;
        line-height: 2;
        span {
          @include sr-only;
        }
        [class*=fa-] {
          font-size: var(--text-base);
        }
      }
    }
  }
}
