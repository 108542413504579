/* GRID
  --------------------------------------- */
@mixin wp-full-width {
  width: 100vw;
  max-width: 100vw;
  margin-left: calc(50% - 50vw);
}
@mixin wp-full-width-no-padding {
  @include wp-full-width;
  margin: 0;
}


/* COLORS
  --------------------------------------- */
@mixin black-trans-12 {
  color: rgba(0,0,0,.12);
}
@mixin black-trans-50 {
  color: rgba(0,0,0,.5);
}
@mixin black-trans-80 {
  color: rgba(0,0,0,.8);
}
@mixin white-trans-50 {
  color: rgba(255,255,255,.5);
}
@mixin white-f1-trans-60 {
  color: rgba(241, 241, 241,.6);
}
@mixin opacity-portfolio {
  opacity: .6;
}


/* BLOCK ELEMENTS
  --------------------------------------- */
@mixin d-flex {
  display: flex !important;
}
@mixin list-normalize-keep-margin {
  padding: 0;
  list-style: none;
}
@mixin list-normalize {
  @include list-normalize-keep-margin
  margin: 0;
}
@mixin list-inline-block {
  li {
    display: inline-block;
  }
}
@mixin list-normalize-inline-block {
  @include list-normalize;
  &>li {
    display: inline-block;
  }
}
@mixin list-normalize-flexbox {
  @include list-normalize;
  @include d-flex;
  &>li {
    @include d-flex;
  }
}
@mixin last-child-zero-margin {
  &>:last-child {
    margin-bottom: 0;
  }
}
@mixin last-child-inherit-margin {
  &>:last-child {
    margin-bottom: inherit;
  }
}
@mixin last-child-zero-margin-important {
  &>:last-child {
    margin-bottom: 0 !important;
  }
}
@mixin paragraph-spacing {
  margin-bottom: var(--space-sm);
}
@mixin paragraph-spacing-top {
  margin-top: var(--space-sm);
}


/* LINKS & ACTIONS
  --------------------------------------- */
@mixin link-underline {
  text-decoration: underline;
  @include hover-focus {
    text-decoration: underline;
  }
}
@mixin link-no-underline {
  @include hover-focus {
    text-decoration: none;
  }
}
@mixin link-underline-dash {
  border-bottom-width: 1px;
  border-bottom-style: dashed;
  @include hover-focus {
    text-decoration: none;
    border-bottom-style: solid;
  }
}
@mixin hover-full {
  &:hover,
  &:active,
  &:focus,
  &:focus-visible {
    @content;
  }
}
@mixin focus-outline {
  outline: 2px solid var(--link-color-focus-visible);
}
@mixin focus-outline-radius {
  @include focus-outline
  border-radius: 2px;
}
@mixin focus-outline-thin {
  @include focus-outline;
  outline-width: 1px;
  outline-offset: 0px;
}
@mixin tile-hover {
  figure {
    overflow: hidden;
    img {
      will-change: transform, opacity;
      transition: all ease .5s;
    }
  }
  &:hover,&:active,&:focus,&:focus-visible {
    figure {
      img {
        transform: scale(1.05);
      }
    }
  }
}



/* FONTS
  --------------------------------------- */
@mixin sans-text {
  font-family: $font-family-sans;
}
@mixin mono-text {
  font-family: $font-family-mono;
}
@mixin extended-text {
  font-family: $font-family-extended;
}

@mixin fa-text {
  font-family: $font-family-awesome;
}

@mixin overline {
  @include extended-text;
  color: var(--color-overline);
  font-size: var(--text-xs);
  letter-spacing: var(--tracking-wider);
  line-height: var(--leading-none);
  font-weight: var(--weight-base);
  text-transform: uppercase;
}
@mixin overline-sm {
  @include overline;
  font-size: var(--text-2xs);
}

/* IMAGES
  --------------------------------------- */
@mixin svg-color-fill($color) {
  path {
    @include transition($link-transition);
    fill: $color;
  }
}
@mixin figure-aspect-ratio($ratio) {
  aspect-ratio: #{$ratio};
  overflow: hidden;
  img {
    object-fit: cover;
    display: block;
    width: 100%;
    height: 100%;
  }
}


/* NAVS
  --------------------------------------- */
@mixin social-nav {
  @include list-normalize-flexbox;
  flex-wrap: wrap;
  li {
    margin-right: var(--gap-xs);
    a {
      @extend .btn;
      @extend .btn-social;
    }
  }
}
@mixin slick-nav {
  @include list-normalize;
  @include list-inline-block;
  position: absolute;
  bottom: 0;
  right: map-get($grid-container-margins, 'xs')/2;
  @include media-breakpoint-up(lg) {
    right: map-get($grid-container-margins, 'lg')/2;
  }
}


/* FORMS
  --------------------------------------- */
@mixin select-control($color) {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-repeat: no-repeat;
  background-position: right .75rem center;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M238 429.3l22.6-22.6 192-192L475.3 192 430 146.7l-22.6 22.6L238 338.7 68.6 169.4 46 146.7 .7 192l22.6 22.6 192 192L238 429.3z" fill="rgb(#{$color})"/></svg>');
  background-size: auto 1em;
  padding-right: 2.75em !important;
  line-height: 1.1875;
}
@mixin checkbox-radio-control {
  display:none;
  &+label {
    &:before {
      font: var(--fa-font-sharp-regular);
      display: inline-block;
      letter-spacing: .5em;
      color: var(--input-bg);
    }
  }
  &:checked {
    &+label {
      &:before {
        font: var(--fa-font-sharp-solid);
        color: var(--color-yellow);
      }
    }
  }
}
@mixin checkbox-control {
  @include checkbox-radio-control;
  &+label {
    &:before {
      content: "\f0c8";
    }
  }
  &:checked {
    &+label {
      &:before {
        content: "\f14a";
      }
    }
  }
}
@mixin radio-control {
  @include checkbox-radio-control;
  &+label {
    &:before {
      content: "\f111";
    }
  }
  &:checked {
    &+label {
      &:before {
        content: "\f058";
      }
    }
  }
}



/* GRID
  --------------------------------------- */
@mixin wp-full-width-no-padding {
  width: 100vw;
  max-width: 100vw;
  margin: 0;
  margin-left: calc(50% - 50vw);
}
@mixin container-full-width {
  max-width: none !important;
}
@mixin main-column-width-narrow {
  @include make-col-ready();
  @include make-col(12);
  @include media-breakpoint-up(md) {
    @include make-col(10);
    @include make-col-offset(1);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(8);
    @include make-col-offset(2);
  }
  @include media-breakpoint-up(xl) {
    @include make-col(6);
    @include make-col-offset(3);
  }
}
@mixin main-column-width-wide {
  @include make-col-ready();
  @include make-col(12);
  @include media-breakpoint-up(md) {
    @include make-col(12);
    @include make-col-offset(0);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(10);
    @include make-col-offset(1);
  }
  @include media-breakpoint-up(xl) {
    @include make-col(8);
    @include make-col-offset(2);
  }
}
@mixin main-column-width-full {
  @include make-col-ready();
  @include make-col(12);
  @include media-breakpoint-up(md) {
    @include make-col(12);
    @include make-col-offset(0);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(12);
    @include make-col-offset(0);
  }
}
