.wp-full-width {
  @include wp-full-width-no-padding;
}
.pdm-block {
  padding-top: var(--gap-80);
  padding-bottom: var(--gap-80);
}
main.main {
//   @include headings-top-spacing;
/*
  &>:first-child {
    &.pdm-block {
      margin-top: 0;
    }
  }
  &>:last-child {
    &.pdm-block {
      margin-bottom: calc(var(--space-lg)*-1);
    }
  }
*/
}
.columns-block {
  padding-bottom: var(--gap-xl);
  .title {
    .overline {
      margin-bottom: var(--gap-lg);
    }
    h2 {
      margin-bottom: var(--gap-64);
    }
  }
  .synopsis {
    @include extended-text;
    @include last-child-zero-margin;
    font-size: var(--text-xl);
    margin-bottom: var(--gap-80);
  }
  .columns {
    .snippet {
      @include last-child-zero-margin;
      font-size: var(--text-sm);
      margin-bottom: var(--gap-3xl);
      h3 {
        @include overline;
        margin-bottom: var(--gap-lg);
      }
    }
    &.list {
      .snippet {
        font-size: var(--text-base);
        ul {
          @include list-normalize;
          li {
            margin: var(--gap-2xs) 0;
          }
        }
      }
    }
  }
}
.marquee-block {
  @include media-breakpoint-up(md) {
    padding-top: var(--gap-128);
    padding-bottom: var(--gap-128);
  }
  @include media-breakpoint-up(lg) {
    padding-top: var(--gap-144);
    padding-bottom: var(--gap-144);
  }
  .details {
    @include last-child-zero-margin;
    @include extended-text;
    text-align: center;
    font-size: var(--text-2xl);
    figure.icon {
      width: 52px;
      margin: 0 auto var(--gap-3xl);
    }
    .marquee-link {
      @include mono-text;
      @include link-underline-dash;
      display: inline-block;
      font-size: var(--text-sm);
      margin-top: var(--gap-3xl);
      color: var(--color-black-111);
    }
  }
}
.portfolio-block {
  padding-top: var(--gap-64);
  padding-bottom: var(--gap-64);
  .title {
    margin-bottom: 0;
    h2 {
      @include overline;
      margin-bottom: 0;
    }
    .project-nav {
      @include list-normalize;
      @include list-inline-block;
    }
  }
  .portfolio {
    margin-top: var(--gap-2xl);
    .featured-work {
      .work-link {
        @include link-no-underline;
        color: var(--color-portfolio);
        .visual-example {
          padding: var(--gap-3xl);
          border-color: var(--color-accent);
          border-bottom-width: 1px;
          border-bottom-style: solid;
          margin-bottom: var(--gap-md-s);
        }
        figure {
          @include figure-aspect-ratio('1/1.02');
          @include transition($link-transition);
          border-radius: 4px;
          margin-bottom: 0;
          position: relative;
          background-color: var(--color-accent-dark) !important;
          background-blend-mode: normal,luminosity;
          background-size: cover !important;
          background-repeat: no-repeat !important;
          background-position: 50% !important;
/*
          background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0.00) 29.17%), linear-gradient(0deg, #FFFBF7 0%, #FFFBF7 100%), linear-gradient(0deg, var(--color-accent-dark) 0%, var(--color-accent-dark) 100%);
          background-blend-mode: normal, multiply, screen, normal;
          background-size: cover !important;
          background-repeat: no-repeat !important;
          background-position: 50% !important;
          background-color: lightgray 50% !important;
*/
        }
        header {
          @include d-flex;
          @include mono-text;
          flex-direction: column-reverse;
          h3 {
            color: var(--color-portfolio-title);
            margin-bottom: var(--gap-md-s);
          }
          .categories {
            @include opacity-portfolio;
            @include sans-text;
            margin-bottom: var(--gap-md-s);
          }
        }
        .synopsis {
          @include opacity-portfolio;
          @include last-child-zero-margin;
          font-size: var(--text-md);
          margin-bottom: var(--gap-md-s);
        }
        .view-site {
          @include mono-text;
          @include opacity-portfolio;
          @include transition($link-transition);
          margin-bottom: 0;
          font-size: var(--text-xs-sm);
          [class*=fa-] {
            @include transition($link-transition);
          }
        }
        @include hover-full {
          figure {
            background-color: transparent !important;
          }
          .view-site {
            opacity: 1;
            [class*=fa-] {
              color: var(--color-accent);
            }
          }
        }
      }
    }
  }
}
.testimonials-block {
  .testimonials {
    .testimonial {
      margin-top: var(--gap-3xl);
      blockquote {
        @include extended-text;
        font-size: var(--text-lg);
        &:before {
          font-size: var(--text-2xl);
          content: '\201C';
          display: block;
          line-height: var(--leading-none);
        }
        cite {
          @include d-flex;
          @include sans-text;
          margin-top: var(--gap-lg);
          align-items: center;
          font-size: var(--text-sm);
          font-style: normal;
          figure {
            margin: 0 var(--gap-xs) 0 0;
            img {
              width: 40px;
              border-radius: 20px;
            }
          }
          span {
            .org {
              @include black-trans-50;
              display: block;
              font-size: var(--text-xs-sm);
            }
          }
        }
      }
    }
    &+hr {
      margin: var(--gap-64) 0;
    }
  }
  .collaborators {
    font-size: var(--text-xs);
    color: var(--color-black-222);
    ul {
      @include list-normalize;
      columns: 2;
      gap: 32px;
      @include media-breakpoint-up(md) {
        columns: 3;
      }
      @include media-breakpoint-up(lg) {
        columns: 4;
      }
      li {
        line-height: var(--leading-tight);
        margin-bottom: var(--gap-xs);
      }
    }
  }
}
