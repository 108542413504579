.bg-black,.bg-black-111,.bg-black-171,.bg-black-222 {
  --color-body-text: var(--color-white);
  --color-overline: var(--color-white-f1);
  --color-portfolio: var(--color-white);
}
.bg-black {
  --color-body-bg: var(--color-black);
}
.bg-black-111 {
  --color-body-bg: var(--color-black-111);
}
.bg-black-171 {
  --color-body-bg: var(--color-black-171);
}
.bg-black-222 {
  --color-body-bg: var(--color-black-222);
}
.accent-blue {
  --color-accent: var(--color-blue);
  --color-accent-dark: var(--color-blue-dark);
}
.accent-green {
  --color-accent: var(--color-green);
  --color-accent-dark: var(--color-green-dark);
}
.accent-orange {
  --color-accent: var(--color-orange);
  --color-accent-dark: var(--color-orange-dark);
}
.accent-purple {
  --color-accent: var(--color-purple);
  --color-accent-dark: var(--color-purple-dark);
}

[class*="bg-"] {
  background: var(--color-body-bg);
  color: var(--color-body-text);
}
