// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

$grid-gutter-width:     32px;
$grid-gutter-height:    $grid-gutter-width/2;

$base-spacer:           16px;


/* COLORS
  --------------------------------------- */
$body-color:            var(--color-body-text);
$body-bg:               var(--color-body-bg);
$brand-primary:         var(--color-brand-primary);
$brand-secondary:       var(--color-brand-secondary);

$link-color:            var(--link-color);
$link-hover-color:      var(--link-color-hover);
$link-decoration:       none;
$link-hover-decoration: underline;
$link-transition:       all .125s ease-in-out;
$link-dash-transition:  all .125s ease-in-out,border-style .125s ease-in-out;


$black:                 #000;


/* FONTS
  --------------------------------------- */
$font-family-sans:      var(--font-sans);
$font-family-serif:     var(--font-serif);
$font-family-extended:  var(--font-extended);
$font-family-awesome:   var(--font-awesome);
$font-family-mono:      var(--font-mono);
$font-family-base:      $font-family-sans;

$font-size-base:        1rem;             //  16
$font-size-xs:          var(--text-xs);   //  12
$font-size-sm:          var(--text-sm);   //  14
$font-size-lg:          var(--text-lg);   //  18
$font-size-xl:          var(--text-xl);   //  20
$font-size-2xl:         var(--text-2xl);  //  24

$h1-font-size:          var(--text-h1);   //  24
$h2-font-size:          var(--text-h2);   //  22
$h3-font-size:          var(--text-h3);   //  20
$h4-font-size:          var(--text-h4);   //  18
$h5-font-size:          var(--text-h5);   //  16
$h6-font-size:          var(--text-h6);   //  15

$font-weight-normal:    var(--weight-normal);
$font-weight-medium:    var(--weight-medium);
$font-weight-semibold:  var(--weight-semibold);
$font-weight-bold:      var(--weight-bold);
$font-weight-base:      $font-weight-normal;

$display-font-family:   $font-family-base;
$headings-font-family:  var(--font-extended);
$headings-font-weight:  $font-weight-bold;
$headings-line-height:  var(--leading-tight);

$border-radius:           8px;
$border-radius-md:        16px;
$border-radius-elevation: 0;



/* FORM INPUTS
  --------------------------------------- */
$input-font-size:                var(--input-text-size);
$input-font-weight:              var(--font-normal);
$input-line-height:              1;
$input-height:                   auto;
$input-bg:                       var(--input-bg);
$input-color:                    var(--input-color);
$input-border-color:             var(--input-border-color);
$input-border-width:             1px;
$input-box-shadow:               none;
$input-border-radius:            var(--input-border-radius);
$input-border-radius-lg:         $input-border-radius;
$input-border-radius-sm:         $input-border-radius;
$input-focus-box-shadow:         none;
$input-placeholder-color:        var(--input-placeholder);



/* MISC OVERRIDES
  --------------------------------------- */
$navbar-brand-height:               auto;
$navbar-brand-padding-y:            auto;
$nav-link-height:                   auto;
